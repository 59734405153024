// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$shop_my-pages-primary: mat.define-palette(mat.$indigo-palette);
$shop_my-pages-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$shop_my-pages-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$shop_my-pages-theme: mat.define-light-theme(
  (
    color: (
      primary: $shop_my-pages-primary,
      accent: $shop_my-pages-accent,
      warn: $shop_my-pages-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($shop_my-pages-theme);

/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "_fonts.scss";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #ffffff;
  padding: 0;
  margin: 0;
}

input[type="radio"] {
  color: #a5a5a5;
  width: 20px;
  height: 20px;
}

.custom-loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #f4f4f4 94%, #0000) top/4px 4px no-repeat,
    conic-gradient(#0000 30%, #f4f4f4);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 2s infinite linear;
}

.custom-loader-dark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #CF2B32 94%, #0000) top/4px 4px no-repeat,
    conic-gradient(#0000 30%, #CF2B32);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 2s infinite linear;
}

.custom-select {
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" fill="%239CA3AF"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.25em;
  color: #000;
}

.stories-container {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 20px;
}

.story-item {
  flex: 0 0 auto;
  width: 160px;
  min-height: 130px;
  background-color: #eee;
  border-radius: 8px;
}

.story-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

// TODO ???
.page-layout {
  @apply flex flex-col w-full laptop-sm:grid laptop-sm:grid-cols-6 tablet-md:max-w-750 tablet-md:mx-auto laptop-sm:grid-rows-2 laptop-sm:max-w-full p-3 pt-6 tablet-md:p-10 gap-6;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

.rotate {
  transition: transform 0.3s ease;
}
.rotate180 {
  transform: rotate(-180deg);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

details > summary {
  list-style: none;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */ 
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}
