@font-face {
  font-family: "Calibre";
  src: url(/assets/fonts/Calibre-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Calibre-Semibold";
  src: url(/assets/fonts/Calibre-Semibold.ttf);
  font-display: swap;
}

@mixin font-calibre-semi {
  font-family: "Calibre-Semibold";
  src: url(/assets/fonts/Calibre-Semibold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Calibre-Bold";
  src: url(/assets/fonts/Calibre-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Calibre-Medium";
  src: url(/assets/fonts/Calibre-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Averta-PE";
  src: url(/assets/fonts/Averta-PE-Semibold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Averta-Bold";
  src: url(/assets/fonts/hinted-AvertaStd-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Calibre-Italic";
  src: url(/assets/fonts/hinted-AvertaStd-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Calibre-ThinItalic";
  src: url(/assets/fonts/Calibre-Thin-Italic.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: "Calibre-LightItalic";
  src: url(/assets/fonts/Calibre-Light-Italic.otf) format('opentype');
  font-display: swap;
}